<template>
  <layout-content-vertical-nav :nav-menu-items="navMenuItems">
    <slot></slot>

    <app-card-update />

    <!-- Slot: Navbar -->
    <template #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }">
      <div
        class="navbar-content-container"
        :class="{'expanded-search': shallShowFullSearch}"
      >
        <!-- Left Content: Search -->
        <div class="d-flex align-center">
          <v-icon
            v-if="$vuetify.breakpoint.mdAndDown"
            class="me-3"
            @click="toggleVerticalNavMenuActive"
          >
            {{ icons.mdiMenu }}
          </v-icon>
          <!-- <app-bar-search
            :shall-show-full-search.sync="shallShowFullSearch"
            :data="appBarSearchData"
            :filter="searchFilterFunc"
            :search-query.sync="appBarSearchQuery"
            @update:shallShowFullSearch="handleShallShowFullSearchUpdate(isVerticalNavMenuActive, toggleVerticalNavMenuActive)"
          ></app-bar-search> -->
        </div>

        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center right-row">
          <app-bar-i18n></app-bar-i18n>
          <!-- <app-bar-theme-switcher class="mx-4"></app-bar-theme-switcher> -->

          <!-- <app-bar-chat class="mx-4"></app-bar-chat> -->
          <!-- <app-bar-notification></app-bar-notification> -->
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </template>

    <template>
    </template>

    <!-- Slot: Footer -->
    <template #footer>
      <!-- <div class="d-flex justify-space-between">
        <span>COPYRIGHT &copy; {{ new Date().getFullYear() }} <a
          href="https://themeselection.com"
          class="text-decoration-none"
        >ThemeSelection</a><span class="d-none d-md-inline">, All rights Reserved</span></span>
        <div class="align-center d-none d-md-flex">
          <span>Hand-crafted &amp; Made with</span>
          <v-icon
            color="error"
            class="ms-2"
          >
            {{ icons.mdiHeartOutline }}
          </v-icon>
        </div>
      </div> -->

      <div class="d-flex align-center justify-center py-3 auth-footer-center">
        <p class="ma-0 pr-3 text-center text-body-1">
          {{ t('login.powered_by') }}
        </p>
        <v-img
          :src="appLogoTitle"
          max-width="100px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>
      </div>
    </template>

    <template #v-app-content>
    </template>
  </layout-content-vertical-nav>
</template>

<script>
import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
import AppBarUserMenu from '@core/layouts/components/app-bar/AppBarUserMenu.vue'

// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// App Bar Components
// import AppBarSearch from '@core/layouts/components/app-bar/AppBarSearch.vue'
// import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'

// import AppBarChat from '@core/layouts/components/app-bar/AppBarChat.vue'
// import AppBarNotification from '@core/layouts/components/app-bar/AppBarNotification.vue'
import { mdiMenu, mdiHeartOutline, mdiClose } from '@mdi/js'

import { getVuetify } from '@core/utils'

// Search Data
import { ref, watch } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import themeConfig from '@themeConfig'
import appBarSearchData from '@/assets/app-bar-search-data'

import navMenuItems from '@/navigation/vertical'
import LayoutContentVerticalNav from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'

import AppCardUpdate from '@core/components/app-card-update/AppCardUpdate.vue'

import useAppConfig from '@core/@app-config/useAppConfig'

export default {
  components: {
    LayoutContentVerticalNav,

    AppCardUpdate,

    // AppCustomizer,
    // App Bar Components
    // AppBarSearch,
    // AppBarChat,
    // AppBarThemeSwitcher,

    AppBarI18n,
    AppBarUserMenu,

    // AppBarNotification,
  },
  setup() {
    const $vuetify = getVuetify()
    const { t } = useUtils()
    const intervalNotification = ref(null)
    /* eslint-disable object-curly-newline, operator-linebreak */
    const { menuIsVerticalNavMini, menuIsMenuHidden, appBarType, appBarIsBlurred, footerType, appContentWidth } =
      useAppConfig()

    // Search
    const appBarSearchQuery = ref('')
    const shallShowFullSearch = ref(false)
    const maxItemsInGroup = 5
    const totalItemsInGroup = ref({
      pages: 0,
      files: 0,
      contacts: 0,
    })
    const notifications = ref([])

    watch(appBarSearchQuery, () => {
      totalItemsInGroup.value = {
        pages: 0,
        files: 0,
        contacts: 0,
      }
    })

    const searchFilterFunc = (item, queryText, itemText) => {
      if (item.header || item.divider) return true

      const itemGroup = (() => {
        if (item.to !== undefined) return 'pages'
        if (item.size !== undefined) return 'files'
        if (item.email !== undefined) return 'contacts'

        return null
      })()

      const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1

      if (isMatched) {
        if (itemGroup === 'pages') totalItemsInGroup.value.pages += 1
        else if (itemGroup === 'files') totalItemsInGroup.value.files += 1
        else if (itemGroup === 'contacts') totalItemsInGroup.value.contacts += 1
      }

      return appBarSearchQuery.value && isMatched && totalItemsInGroup.value[itemGroup] <= maxItemsInGroup
    }

    // ? Handles case where in <lg vertical nav menu is open and search is triggered using hotkey then searchbox is hidden behind vertical nav menu overlaty
    const handleShallShowFullSearchUpdate = (isVerticalNavMenuActive, toggleVerticalNavMenuActive) => {
      if ($vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive) {
        toggleVerticalNavMenuActive()
      }
    }

    const toggleAlert = (toggle, notification) => {
      const notificationsStored = localStorage.getItem('notifications')
      if (notificationsStored) {
        const arrNotifications = JSON.parse(notificationsStored)
        arrNotifications.push(notification.id)
        localStorage.setItem('notifications', JSON.stringify(arrNotifications))
      } else {
        localStorage.setItem('notifications', JSON.stringify([notification.id]))
      }
      toggle()
    }

    return {
      navMenuItems,
      handleShallShowFullSearchUpdate,

      // Search
      appBarSearchQuery,
      shallShowFullSearch,
      appBarSearchData,
      searchFilterFunc,

      notifications,
      intervalNotification,
      toggleAlert,

      menuIsVerticalNavMini,
      menuIsMenuHidden,
      appBarType,
      appBarIsBlurred,
      footerType,
      appContentWidth,
      t,

      // themeConfig
      appLogoTitle: themeConfig.app.logoTitle,

      icons: {
        mdiMenu,
        mdiHeartOutline,
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

.navbar-content-container-notification {
  display: block;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 75px;
  position: relative;
  padding-left: 260px;
  transition-duration: 0.3s;
}
.navbar-content-container-notification-pl {
  padding-left: 68px !important;
  transition-duration: 0.3s;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 1263.98px) {
  .navbar-content-container-notification {
    padding-left: 0px;
    transition: 0.3s;
  }
}

// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
  .expanded-search {
    ::v-deep .app-bar-autocomplete-box div[role='combobox'] {
      background-color: transparent;
    }

    > .d-flex > button.v-icon {
      display: none;
    }

    // ===

    & > .right-row {
      visibility: hidden;
      opacity: 0;
    }

    ::v-deep .app-bar-search-toggler {
      visibility: hidden;
    }
  }
}
</style>
