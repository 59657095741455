<template>
  <layout-blank class="position-relative">
    <app-card-update />

    <slot></slot>
  </layout-blank>
</template>

<script>
import LayoutBlank from '@/@core/layouts/variants/blank/LayoutBlank.vue'
import { mdiArrowCollapseDown } from '@mdi/js'
import store from '@/store'

import AppCardUpdate from '@core/components/app-card-update/AppCardUpdate.vue'

export default {
  components: {
    LayoutBlank,
    AppCardUpdate,
  },
  setup() {
    const onUpdate = () => {
      store.dispatch('app/toggleUpdate', false)
    }

    return {
      onUpdate,

      icons: {
        mdiArrowCollapseDown,
      },
    }
  },
}
</script>

<style>
.card-version {
  width: 300px;
  position: fixed !important;
  z-index: 20;
  inset-block-end: 5%;
  inset-inline-end: 30px;
}

.text-mail {
  color: #ff3232;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.01875rem;
  text-decoration: underline;
}
</style>
